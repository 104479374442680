<script setup>
  import { computed, ref, watch } from 'vue';

  const emit = defineEmits(['update:modelValue', 'update:checked']);

  const props = defineProps({
    modelValue: {
      type: [Boolean, Array],
      default: false
    },
    checked: {
      type: [Boolean, Array],
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  });

  //   const localChecked = ref(props.modelValue || props.checked);
  const localChecked = computed({
    get() {
      if (Array.isArray(props.modelValue)) {
        return props.modelValue.includes(props.value);
      }
      return props.modelValue || props.checked;
    },
    set(val) {
      if (Array.isArray(props.modelValue)) {
        const updated = val
          ? [...props.modelValue, props.value]
          : props.modelValue.filter(v => v !== props.value);
        emit('update:modelValue', updated);
      } else {
        emit('update:modelValue', val);
        emit('update:checked', val);
      }
    }
  });
  watch(
    () => props.modelValue,
    newVal => {
      localChecked.value = newVal;
    }
  );

  watch(
    () => props.checked,
    newVal => {
      localChecked.value = newVal;
    }
  );

  const proxyChecked = computed({
    get() {
      return localChecked.value;
    },
    set(val) {
      localChecked.value = val;
      emit('update:modelValue', val);
      emit('update:checked', val);
    }
  });
</script>

<template>
  <input
    v-model="proxyChecked"
    type="checkbox"
    :value="value"
    class="rounded border-2 border-primary bg-gray-300 text-primary shadow-sm hover:bg-primary-darker focus:ring-transparent"
  />
</template>
